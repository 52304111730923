import Header from "components/Header/header";
import Footer from "components/Footer/Footer";
import arraySocials from "json/socials.json";
import sprite from "assets/Images/sprites/sprites.svg";
import contactJson from "json/contacts.json";
import CopyBtn from "components/CopyBtn/CopyBtn";

import "./contacts.sass";

const Contacts = () => {
  return (
    <>
      <section id="contacts__block_wrapper">
        <div className="contacts__content_wrapper">
          <div className="contacts__content_title">
            <h1 className="contacts__title">Контакти</h1>
          </div>
          <div className="contacts__content_block--wrapper">
            <div className="contacts__content_block">
              <h2 className="contacts__description">{contactJson[0].title}</h2>
              <div className="contacts__links_wrapper">
                <dl className="contacts__links_list">
                  {contactJson.map((el, id) => {
                    if (id === 0) return;
                    return (
                      <li key={id + 1}>
                        <span className="title">{el.title}</span>
                        <div className="contacts__link_wrapper">
                          <span className="link">
                            <a
                              style={{ color: "rgba(255, 138, 54, 1)" }}
                              href={el.href}
                            >
                              {el.link}
                            </a>
                            <CopyBtn link={el.link as string} />
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </dl>

                <ul className="contacts__list">
                  {arraySocials.map(({ id, text, icon, link }) => (
                    <a
                      key={id}
                      href={link}
                      target="_blank"
                      className="socials__item"
                      rel="noreferrer"
                    >
                      <div className="socials__item_gradient" />
                      <svg className="socials__list_icon">
                        <use href={`${sprite}#${icon}`} />
                      </svg>
                      {text}
                    </a>
                  ))}
                </ul>
              </div>
            </div>
            <div className="contacts__photo_block" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contacts;
