import "./Socials.sass";
import "assets/animation/animationSocials.sass";
import sprite from "assets/Images/sprites/sprites.svg";
import arraySocials from "json/socials.json";
import back from "assets/Images/Home/socials/background.png";
import girlsbig from "assets/Images/Home/socials/girlsbig.png";
import splashConect from "assets/Images/Home/socials/splashConect.png";
import { useEffect, useRef } from "react";
import { useMobileAnimation, useOnScreen } from "hooks";

const Socials = () => {
  const refMainElement = useRef<HTMLDivElement>(null);
  const refListElement = useRef<HTMLUListElement>(null);
  const refImgGirlElement = useRef<HTMLImageElement>(null);
  const refSplashGirlElement = useRef<HTMLImageElement>(null);
  const isMobile = useMobileAnimation();

  const isOnScreen = useOnScreen(refMainElement, "0px", isMobile ? 0.3 : 0.5);

  useEffect(() => {
    const imgEl = refImgGirlElement.current;
    const imgSplashEl = refSplashGirlElement.current;
    if (refListElement.current) {
      const children = refListElement.current.children;

      Array.from(children).forEach((child, ind) => {
        if (isOnScreen) {
          child.classList.add(`socials__item_animation-in-${ind}`);
          child.classList.remove(`socials__item_animation-out-${ind}`);
        } else {
          child.classList.add(`socials__item_animation-out-${ind}`);
        }
      });
    }

    if (imgEl) {
      if (isOnScreen) {
        imgEl.classList.add(`socials__girl_animation`);
        imgSplashEl?.classList.add(`socials__splash_animation`);
        setTimeout(() => {
          imgEl.classList.add("socials__img_anim");
        }, 3050);
      } else {
        imgSplashEl?.classList.add(`socials__splash_animation-out`);
        imgEl.classList.add(`socials__girl_animation-out`);
      }
    }
  }, [isOnScreen]);

  return (
    <div
      ref={refMainElement}
      style={{ backgroundImage: ` url(${back})` }}
      className="socials__box"
    >
      <div className="socials_box_position">
        <div className="socials__box_content">
          <h2 className="socials__title">Соціальні мережі</h2>
          <ul className="socials__list" ref={refListElement}>
            {arraySocials.map(({ id, text, icon, link }) => (
              <a
                key={id}
                href={link}
                target="_blank"
                className="socials__item"
                rel="noreferrer"
              >
                <div className="socials__item_gradient" />
                <svg className="socials__list_icon">
                  <use href={`${sprite}#${icon}`} />
                </svg>
                {text}
              </a>
            ))}
          </ul>
        </div>
        <div className="splash">
          <img
            ref={refSplashGirlElement}
            className="splash-img"
            src={splashConect}
            alt="Приэднуйся"
          />
        </div>
        <img
          ref={refImgGirlElement}
          className="socials__img_girl"
          src={girlsbig}
          alt="girls"
        />
      </div>
    </div>
  );
};

export default Socials;
