import { FC } from "react";
import "./DonateCoins.sass";
import coin from "assets/Images/Donate/coinnew.png";

interface IPropsDonateCoins {
  mouseCords: { mouseX: number; mouseY: number };
}

interface CoinStyles {
  willChange: string;
  transform: string;
}

const DonateCoins: FC<IPropsDonateCoins> = ({ mouseCords }) => {
  const { mouseX, mouseY } = mouseCords;

  const coinStyles: CoinStyles = {
    willChange: "transform",
    transform: `translate(${mouseX * 0.003}px, ${mouseY * 0.009}px)`,
  };

  const coinStylesTwo: CoinStyles = {
    willChange: "transform",
    transform: `translate(${mouseX * 0.03}px, ${mouseY * 0.015}px)`,
  };

  return (
    <>
      <div className="box-img top-position" style={coinStyles}>
        <img src={coin} className="coin1" />
        <img src={coin} className="coin6" />
      </div>
      <div className="box-img" style={coinStylesTwo}>
        <img src={coin} className="coin4" />
        <img src={coin} className="coin2" />
        <img src={coin} className="coin3" />
        <img src={coin} className="coin5" />
        <img src={coin} className="coin7" />
      </div>
    </>
  );
};

export default DonateCoins;
