import { FC, useState } from "react";
import "./answer.sass";
import "./answermobile.sass";
import AnswerDataJson from "../../../json/answers.json";
import decorateR from "../../../assets/Images/Answer/decorR.svg";
import decorateR768 from "../../../assets/Images/Answer/decorR768.svg";
import decorateL from "../../../assets/Images/Answer/decorL.svg";
import decorateL768 from "../../../assets/Images/Answer/decorL768.svg";
import AnswerCard from "./answercard";

const Answer: FC = () => {
  const [currentCardIndex, setCurrentIndex] = useState<null | number>(null);

  const updateCurrentIndexHandler = (id: number | null): void => {
    setCurrentIndex(id);
  };

  return (
    <section id="answer__wrapper">
      <h1 className="answer__title">Відповіді на найчастіші питання</h1>
      <div className="answer__block_wrapper-flex">
        <div className="answer___block_wrapper">
          {AnswerDataJson.map((el, id) =>
            id % 2 === 0 ? (
              <AnswerCard
                currentIndex={currentCardIndex}
                updater={updateCurrentIndexHandler}
                key={id}
                title={el.title}
                text={el.text}
                id={el.id}
              />
            ) : null
          )}
        </div>
        <div className="answer___block_wrapper">
          {AnswerDataJson.map((el, id) =>
            id % 2 !== 0 ? (
              <AnswerCard
                currentIndex={currentCardIndex}
                updater={updateCurrentIndexHandler}
                key={id}
                title={el.title}
                text={el.text}
                id={el.id}
              />
            ) : null
          )}
        </div>
      </div>
      <img src={decorateR} alt="decor" className="answer__decor_right" />
      <img src={decorateR768} alt="decor" className="answer__decor_right768" />
      <img src={decorateL} alt="decor" className="answer__decor_left" />
      <img src={decorateL768} alt="decor" className="answer__decor_left768" />
    </section>
  );
};

export default Answer;
