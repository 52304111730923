import { useEffect, useState, useRef, RefObject } from "react";

export default function useOnScreen(
  ref: RefObject<HTMLElement>,
  rootMargin = "0px",
  threshold = 0.5
) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsOnScreen(true);

          if (observerRef.current) {
            observerRef.current.disconnect();
          }
        }
      },
      { rootMargin, threshold }
    );

    if (ref.current && observerRef.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref, rootMargin, threshold]);

  return isOnScreen;
}
