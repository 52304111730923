import background from "../../../assets/Images/Home/backgroundImgVideo.png";
import peopleAndSplash from "../../../assets/Images/Home/peopleAndSplash.png";
import imgYouTube from "../../../assets/Images/Home/imgYouTube.png";
import "./VideoGalarySection.sass";
import "assets/animation/fadeShow.sass";
import sprite from "../../../assets/Images/sprites/sprites.svg";
import { useEffect, useRef, useState } from "react";
import Modal from "components/Modal/modal";
import { images } from "json/imgGallery";
import { useOnScreen, useMobileAnimation } from "hooks/index";
import ModalImg from "./ModalImg/ModalImg";

const VideoGalarySection = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [img, setImg] = useState<number>(0);
  const elementRef = useRef<HTMLDivElement>(null);
  const elementImgRef = useRef<HTMLDivElement>(null);
  const elementVideoRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobileAnimation();
  const isOnScreen = useOnScreen(elementRef, "0px", isMobile ? 0.3 : 0.5);

  useEffect(() => {
    if (isOnScreen) {
      if (elementImgRef.current && elementVideoRef.current) {
        elementVideoRef.current.classList.add("fadeInRight");
        elementImgRef.current.classList.add("fadeInLeft");
        // elementVideoRef.current.classList.remove("fadeOutRight");
        // elementImgRef.current.classList.remove("fadeOutLeft");
      }
    } else {
      if (elementImgRef.current && elementVideoRef.current) {
        elementVideoRef.current.classList.remove("fadeInRight");
        elementImgRef.current.classList.remove("fadeInLeft");
        // elementVideoRef.current.classList.add("fadeOutRight");
        // elementImgRef.current.classList.add("fadeOutLeft");
      }

      // elementRef.current && (elementRef.current.style.opacity = "0.1");
    }
  }, [isOnScreen]);

  const changedShowModal = (): void => {
    setShowModal(!showModal);
  };

  const changeImg = (id: number): void => {
    setImg(id);
  };

  const selectedImg = (id: number): void => {
    changeImg(id);
    changedShowModal();
  };

  const changeSelectedImg = (value: number): void => {
    let newId = images[img].id + value;

    if (images.length - 1 < newId) {
      setImg(images[0].id);
    } else if (newId < 0) {
      setImg(images[images.length - 1].id);
    } else {
      setImg(newId);
    }
  };

  return (
    <section
      style={{ backgroundImage: `url(${background})` }}
      className="video__main_box"
      id="video__section"
    >
      <div ref={elementRef} className="video__box_content">
        <div ref={elementImgRef} className="video__left_block">
          <img
            className="video__img_people"
            src={peopleAndSplash}
            alt="people"
          />
          <div className="video__text-block">
            <h2 className="video__text_title">Приєднюйся зараз</h2>
            <ul className="video__list">
              <li className="video__list_item">
                І тебе чекають неймовірні пригоди у{" "}
                <span className="video__list_item_special_text">
                  Liberty RolePlay.
                </span>
              </li>
              <li className="video__list_item">
                Тебе чекають сотні українців які вже насолоджуються віртуальним
                життям.
              </li>
              <li className="video__list_item">
                Почни своє життя в ролі мафіозі, професійного лікаря,
                губернатора штату, генгсти або короля світу.
              </li>
            </ul>
          </div>
        </div>
        <div ref={elementVideoRef} className="video__right_block">
          <div className="video_gallery_block">
            <a
              className="video__video_link rainbow"
              href="https://www.youtube.com/watch?v=60CDvG4owVY"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{ backgroundImage: `url(${imgYouTube})` }}
                className="video_play_block"
              >
                <svg className="video__icon_youTube">
                  <use href={`${sprite}#play`}></use>
                </svg>
              </div>
            </a>
            {/* <img className="video__img_small" src={src} alt="img" /> */}
            <ul className="video_list_img">
              {images.map(({ id, src }) => (
                <li
                  className="video_list_item"
                  onClick={() => selectedImg(id)}
                  key={id}
                  style={{ backgroundImage: `url(${src})` }}
                ></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal close={() => changedShowModal()}>
          <ModalImg
            changeSelectedImg={changeSelectedImg}
            isMobile={isMobile}
            changeImg={changeImg}
            img={img}
            changedShowModal={changedShowModal}
          />
        </Modal>
      )}
    </section>
  );
};

export default VideoGalarySection;
