import { FC } from "react";
import { images } from "json/imgGallery";
import sprite from "assets/Images/sprites/sprites.svg";
import SelectImgCirlce from "../SelectImgCirlce/SelectImgCirlce";
import "./ModalImg.sass";

interface IMyProps {
  changeSelectedImg: (id: number) => void;
  isMobile: boolean;
  changeImg: (id: number) => void;
  img: number;
  changedShowModal: () => void;
}

const ModalImg: FC<IMyProps> = ({
  changeSelectedImg,
  isMobile,
  changeImg,
  img,
  changedShowModal,
}) => {
  let touchStartX: number | null = null;

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (window.innerWidth < 1000) touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (!touchStartX) return;
    const touchEndX = e.touches[0].clientX;
    const diff = touchStartX - touchEndX;
    // console.log(diff);
    if (diff > 10) {
      changeSelectedImg(1);
    } else if (diff < -10) {
      changeSelectedImg(-1);
    }
    touchStartX = null;
  };

  return (
    <div className="video__box_gallery">
      <div
        onClick={() => changeSelectedImg(-1)}
        className="video_img_box_left_arrow"
      >
        <svg className="video__icon">
          <use href={`${sprite}#arrowLeft`}></use>
        </svg>
      </div>
      <div className="video__box_img_gallery">
        <img
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          className={`video__img_gallery ${isMobile ? "video__mobile" : ""}`}
          src={images[img].srcBig}
          alt="game img"
        />
        <SelectImgCirlce changeImg={changeImg} imgId={img} />
        <button onClick={changedShowModal} className="video__exit">
          &#x2715;
        </button>
      </div>
      <div
        onClick={() => changeSelectedImg(1)}
        className="video_img_box_right_arrow"
      >
        <svg className="video__icon">
          <use href={`${sprite}#arrowRight`}></use>
        </svg>
      </div>
    </div>
  );
};

export default ModalImg;
