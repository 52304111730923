import React from "react";
import "./referralSystem.sass";
import Button from "components/Button/buton";
import Decor1back from "./images/imagesForDecor.png";
import Decor2back from "./images/imagesForDecor1.png";
import Decor3back from "./images/imagesForDecor3.png";
import { Link } from "react-router-dom";

const RefferalSystem = () => {
  return (
    <section id="ref_section">
      <div className="ref_wrapper">
        <img src={Decor1back} alt="decor" className="ref_decor1" />
        <img src={Decor2back} alt="decor" className="ref_decor2" />
        <img src={Decor3back} alt="decor" className="ref_decor3" />
        <div className="ref_content">
          <div className="ref_description">
            <h2 className="ref_title">Медійна персона?</h2>
            Приєднюйся до нашої унікальної реферальної програми та отримуй
            унікальні призи, а особливо успішним учасникам реферальної програми
            ми пропонуємо приєднатись до нашої партнерської програми!
          </div>
          <Link to={"/referral"} className="ref_btn">
            Реферальна програма
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RefferalSystem;
