import { FC } from "react";
import sprite from "../../../assets/Images/sprites/sprites.svg";

interface Props {
  title: string;
  text: string;
  id: number;
  currentIndex: number | null;
  updater?: (id: number | null) => void;
}

const AnswerCard: FC<Props> = ({ updater, currentIndex, text, id, title }) => {
  const updateIndexHandler = (id: number) => {
    if (updater) {
      if (id === currentIndex) {
        updater(null);
      } else {
        updater(id);
      }
    }
  };

  return (
    <div
      className={
        currentIndex === id
          ? "answer__card_wrapper-open"
          : "answer__card_wrapper-close"
      }
      onClick={() => updateIndexHandler(id as number)}
    >
      <div className="answer__card_content">
        <h1 className="answer__card_title">{title}</h1>
        <span className="answer__card_text">{text}</span>
      </div>
      <button className="answer__card_btn">
        <svg
          style={currentIndex === id ? { fill: "rgba(255, 138, 54, 1)" } : {}}
          className="answer__icon_tick"
        >
          <use href={`${sprite}#arrow`} />
        </svg>
      </button>
    </div>
  );
};

export default AnswerCard;
