import "./Footer.sass";
import back from "assets/Images/Footer/footerBack.png";
import arrayLink from "json/footer.json";
import sprite from "assets/Images/sprites/sprites.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer
      id="footer"
      className="footer__main_box"
      // style={{ backgroundImage: `url(${back})` }}
    >
      <img className="footer-img" src={back} alt="img" />
      <svg className="footer__logo">
        <use href={`${sprite}#libertyFooter`} />
      </svg>
      <ul className="footer__list">
        {arrayLink.map(({ text, id, link }) => (
          <Link to={link} onClick={scrollToTop} key={id}>
            <li className="footer__list_item" key={id}>
              <span>{text}</span>
            </li>
          </Link>
        ))}
      </ul>
      <div className="footer__box_root">
        <p>Всі права захищено</p>
        <p>©Liberty 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
