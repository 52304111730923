import React, { FC, useContext, useEffect, useState } from "react";
import logo from "../../assets/Images/Logo.svg";
import UseLinkNavigate from "hooks/useLinkNavigate";
import Burger from "../../components/Burger/burger";
import HeaderButtons from "../../json/header.json";
import Rightmenu from "./rightmenu";
import "./header.sass";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "Context";

const Header: FC = () => {
  const navigate = useNavigate();

  const { toogleMenuState, changeToogleMenuHandler }: any = useContext(
    AppContext as any
  );

  const redirectHandler = (id: number) => {
    if (id === 0 || id === 3) {
      navigate("/");
    } else if (id === 6) {
      navigate("/donate");
    }
    setTimeout(() => {
      UseLinkNavigate(id);
    }, 100);
    changeToogleMenuHandler(false);
  };

  return (
    <>
      <div id="header__wrapper">
        <div className="header__content">
          <Link to={"/"}>
            <img src={logo} alt="Logo" className="header__logotype" />
          </Link>
          <ul className="header__buttons_list">
            {HeaderButtons.map((el) => {
              return (
                <li
                  key={el.id}
                  onClick={() => redirectHandler(el.id as number)}
                >
                  <button className="header__buttons">
                    <span>{el.title}</span>
                    <div className="header__btn_underline" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="header__burger_wrapper">
          <Burger />
        </div>
        {toogleMenuState && <Rightmenu />}
      </div>
    </>
  );
};

export default Header;
