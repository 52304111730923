import { useEffect, useRef, useState } from "react";
import "./ReferralList.scss";
import Coins from "./images/coins.png";
import { refRewards } from "./referralAwards";

let scrollPosition = 0;

const ReferralList = () => {
  const container = useRef<HTMLUListElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  let refLvl = 100;

  function handleMouseWheel(event: React.WheelEvent) {
    const delta = Math.sign(event.deltaY);
    const containerWidth = container.current
      ? container.current.offsetWidth
      : 0;
    const containerScrollWidth = container.current
      ? container.current.scrollWidth
      : 0;

    scrollPosition += delta * 100;

    scrollPosition = Math.max(
      0,
      Math.min(scrollPosition, containerScrollWidth - containerWidth)
    );

    if (container.current) {
      container.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }

  // function disableScrolling() {
  //   var x = window.scrollX;
  //   var y = window.scrollY;
  //   window.onscroll = function () {
  //     window.scrollTo(x, y);
  //   };
  // }
  // function enableScrolling() {
  //   window.onscroll = function () {};
  // }
  useEffect(() => {
    const checkHover = (event: MouseEvent) => {
      if (container.current) {
        const rect = container.current.getBoundingClientRect();
        const isInElement =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        setIsHovering(isInElement);
      }
    };

    document.addEventListener("mousemove", checkHover);

    return () => {
      document.removeEventListener("mousemove", checkHover);
    };
  }, []);

  useEffect(() => {
    if (isHovering) {
      // disableScrolling();
      document.body.style.overflowY = "hidden";
    } else {
      // enableScrolling();
      document.body.style.overflowY = "auto"; // Змінено на "auto"
    }
  }, [isHovering]);

  const isConditionGood = (num: any) => {
    return refLvl >= num + 1;
  };

  const changeBack = (type: number): string => {
    if (type === 1) return `red`;
    if (type === 2) return `pink`;
    if (type === 3) return `blue`;
    if (type === 4) return `green`;
    if (type === 5) return `smallpink`;
    if (type === 6) return `gold`;
    return "";
  };

  const changeImage = (img: number): any => {
    if (img === 1) return require("./images/coins.png");
    if (img === 2) return require("./images/money.png");
    if (img === 3) return require("./images/standartcase.png");
    if (img === 4) return require("./images/dailycase.png");
    if (img === 5) return require("./images/premiumcase.png");
    if (img === 6) return require("./images/contract.png");
  };

  const changeColorSum = (type: number): string => {
    switch (type) {
      case 1:
      case 3:
      case 6:
      case 2:
        return "rgba(233, 61, 249, 1)";
      case 4:
      case 5:
        return "rgba(68, 255, 255, 1)";
      default:
        return "";
    }
  };

  return (
    <div className="ref_list">
      <ul
        className="referrals__reward_wrapper"
        onWheel={handleMouseWheel}
        ref={container}
      >
        {refRewards.map(({ reward, type, img, sum }, id) => {
          return (
            <li
              key={id}
              className={
                isConditionGood(id)
                  ? `referrals__reward_list ${changeBack(type as number)}`
                  : "referrals__reward_list-no"
              }
            >
              <div
                className={
                  isConditionGood(id)
                    ? "referrals__spray_svg"
                    : "referrals__spray_svg-no"
                }
              >
                <div className="ref_manwrap">
                  <img
                    src={require("./images/man.png")}
                    alt="man"
                    className="referrals_mansvg"
                  />
                  <span className="referrals_number">
                    {id + 1 < 10 ? `${"0" + (id + 1)}` : id + 1}
                  </span>
                </div>
              </div>
              <img
                loading="lazy"
                src={changeImage(img as number)}
                alt="award_image"
                className="referrals__coinsSvg"
              />
              {sum && (
                <span
                  className="ref_sum_title"
                  style={{ color: changeColorSum(type as number) }}
                >
                  x{sum}
                </span>
              )}
              <span className="ref_keys_title">{reward}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ReferralList;
