export const infoPrize = [
  {
    id: 0,
    text: "16.000 LC",
  },
  {
    id: 1,
    text: "2.400.000$",
  },
  {
    id: 2,
    text: "20 Щоденних Кейсів ",
  },
  {
    id: 3,
    text: "12 Стандарт Кейсів",
  },
  {
    id: 4,
    text: "2 Преміум Кейс",
  },
  {
    id: 5,
    text: "1 Ліберті Кейс",
  },
  {
    id: 6,
    text: "Можливість приєднатись до партнерської програми",
  },
];
