interface IBonuses {
  id: number;
  money: number;
  bonus: number;
}

export const bonuses: IBonuses[] = [
  {
    id: 1,
    money: 5000,
    bonus: 15,
  },
  {
    id: 2,
    money: 20000,
    bonus: 25,
  },
  {
    id: 3,
    money: 50000,
    bonus: 50,
  },
];
