import { CardStatus } from "./greeting";
import UseLinkNavigate from "hooks/useLinkNavigate";
import Button from "../../../components/Button/buton";
import CopyBtn from "components/CopyBtn/CopyBtn";
import discord from "../../../assets/Images/Home/discord.svg";
import { useEffect, useState } from "react";
import { _ipServerAdress, ip } from "json/ip";

// interface IOnlineStatus<T> {
//   online: T;
//   allies: T;
// }

interface server {
  gamemode: string;
  lang: string;
  maxplayers: number;
  name: string;
  peak: number;
  players: number;
  url: string;
}

const StatisticCard = ({ isActive }: { isActive: CardStatus }) => {
  const [server, setServer] = useState<server>();
  const buttonJoinhandler = (id: number) => {
    UseLinkNavigate(id);
  };

  useEffect(() => {
    fetch("https://cdn.rage.mp/master/")
      .then((response) => response.json())
      .then((data) => setServer(data[ip]))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  console.log(server);
  const checkStateComponent = (state: CardStatus) => {
    switch (state) {
      case "active":
        return (
          <div className="homepage__statistic">
            <div className="homepage__statistic_left">
              <div className="homepage__statistic_left--title">
                <span className="title">Liberty RP</span>
                <span className="status">Онлайн</span>
              </div>
              <div className="homepage__stistic_online--status">
                <div className="homepage__statistic_value--wrapper">
                  <span className="online">{server?.players}</span>
                  <span className="offline">/{server?.maxplayers}</span>
                </div>
                <span>Гравців в онлайні</span>
              </div>
            </div>
            <div className="homepage__statistic_right">
              <div className="homepage__statistic_adress">
                <div>
                  <span className="homepage__copy_title">IP адреса:</span>
                  <CopyBtn link={_ipServerAdress} />
                </div>
                <span>{_ipServerAdress}</span>
              </div>
              <Button
                btnHandler={buttonJoinhandler}
                text="Почати грати"
                id={0}
              />
            </div>
          </div>
        );
      case "notactive":
        return (
          <div className="homepage__statistic">
            <div className="homepage__statistic_left--notactive">
              <span>Приєднайся до нової хвилі ЗБТ</span>
              {/* <span>Скоро відкриття!</span> */}
            </div>
            <div className="homepage__statistic_right--notactive">
              <span>Сервер в діскорді</span>
              <img src={discord} alt="discord_svg" className="discord_svg" />
              <Button
                btnHandler={buttonJoinhandler}
                id={2}
                text="Приєднатись"
              />
            </div>
          </div>
        );
    }
  };

  return checkStateComponent(isActive);
};

export default StatisticCard;
