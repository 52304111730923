export const refRewards = [
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "Стандарт Кейс",
    img: 3,
    type: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "Стандарт Кейс",
    img: 3,
    type: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "300.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "300.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "3 Щоденних кейси.",
    type: 4,
    img: 4,
    sum: 3,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "3 Щоденних кейси.",
    type: 4,
    img: 4,
    sum: 3,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "400.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "400.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "2 Стандарт Кейси.",
    type: 2,
    img: 3,
    sum: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "2 Стандарт Кейси.",
    type: 2,
    img: 3,
    sum: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "4 Щоденних Кейси",
    img: 4,
    type: 4,
    sum: 4,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "4 Щоденних Кейси",
    img: 4,
    type: 4,
    sum: 4,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "500.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "500.000$",
    type: 3,
    img: 2,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "Преміум кейс",
    type: 5,
    img: 5,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "Преміум кейс",
    type: 5,
    img: 5,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "6 Щоденних Кейсів.",
    img: 4,
    type: 4,
    sum: 6,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "3 Стандарт Кейси.",
    type: 2,
    img: 3,
    sum: 3,

  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "3 Стандарт Кейси.",
    type: 2,
    img: 3,
    sum: 3,

  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "200 LC",
    img: 1,
    type: 1,
  },
  {
    reward: "Ліберті Кейс + можливість контракту",
    img: 6,
    type: 6,
  },
];
