import React, { FC } from "react";
import "./button.sass";

interface ButtonProps {
  text: string;
  btnHandler?: (id: number) => void | undefined;
  id?: number;
}

const Button: FC<ButtonProps> = ({ text, btnHandler, id }) => {
  const btnFuncHandler = (index: number) => {
    if (btnHandler) {
      btnHandler(index);
    }
  };

  return (
    <button
      className="btn__component"
      onClick={() => btnFuncHandler(id as number)}
    >
      <span>{text}</span>
    </button>
  );
};

export default Button;
