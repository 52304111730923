import React, { createContext, useState } from "react";

export const AppContext = createContext({
  isVisible: false,
  showModalWindowhandler: (value: string) => {},
});

const Context = (props: any) => {
  const [isVisible, setVisible] = useState(false);
  const [text, setText] = useState("");
  const [toogleMenuState, setToogleMenuState] = useState(false);

  const showModalWindowhandler = (text: string) => {
    setText(text);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
  };

  const changeToogleMenuHandler = (suggestion: any) => {
    if (suggestion) {
      setToogleMenuState(suggestion);
    }
    setToogleMenuState(!toogleMenuState);
  };

  const value = {
    text,
    isVisible,
    showModalWindowhandler,
    toogleMenuState,
    changeToogleMenuHandler,
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export default Context;
