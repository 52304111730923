import React, { FC, useContext } from "react";
import copy from "clipboard-copy";
import "./CopyBtn.sass";
import { AppContext } from "Context";

const CopyBtn: FC<{ link: string }> = ({ link }) => {
  const { showModalWindowhandler, isVisible }: any = useContext(
    AppContext as any
  );

  const copyAdressHandler = (link: string): void => {
    if (isVisible) return;
    copy(link);
    showModalWindowhandler("Cкопійовано в буфер обміну");
  };

  return (
    <button
      onClick={() => copyAdressHandler(link as string)}
      className="copy_btn"
    />
  );
};

export default CopyBtn;
