import ReferralList from "components/Referral/ReferralList/ReferralList";
import "./Referral.sass";
import ReferralInfo from "components/Referral/ReferralInfo/ReferralInfo";
import ReferralPrize from "components/Referral/ReferralPrize/ReferralPrize";
import { FC, useEffect, useState } from "react";
import jar from "assets/Images/Donate/jar.png";
import ImgOther from "components/Referral/ReferralInfo/ImgOther/ImgOther";
import MediaInfo from "components/Referral/MediaInfo/MediaInfo";
import background from "assets/Images/backgroundliberty.png";

interface IPropAnimation {
  mouseCords: { mouseX: number; mouseY: number };
}

const Referral: FC<IPropAnimation> = ({ mouseCords }) => {
  return (
    <div className="referral-wrapper">
      <div className="referral-main-box">
        <ImgOther mouseCords={mouseCords} />
        <div className="referral-box-info">
          <ReferralInfo />
          <ReferralPrize />
          <MediaInfo />
          <ReferralList />
        </div>
      </div>
    </div>
  );
};

export default Referral;
