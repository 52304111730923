import "./ReferralInfo.sass";
import { steps } from "json/referral/referral";

const ReferralInfo = () => {
  return (
    <div className="box-info">
      <div className="info-box-text">
        <h3 className="info-title">Реферальна програма</h3>
        <p className="info-text">
          Унікальна можливість для медійних людей та і просто звичайних гравців
          отримати донат валюту та багато інших цікавих призів. А для особливо
          продуктивних, ми пропонуємо приєднатись до нашої партнерської
          програми, де учасник зможе заробляти гроші запрошуючи гравців до
          проекту Liberty. Не пропусти момент та запрошуй друзів для хорошого
          старту!
        </p>{" "}
      </div>

      <ul className="list-steps">
        {steps.map(({ img, number, title, id, text }) => (
          <li key={id} className="item-step">
            <div className="box-number">
              <p className="number">{number}</p>
              <div className="background-blur">
                <img className="img-step" src={img.src} alt="" />
              </div>
            </div>
            <div className="box-text">
              <h4 className="title">{title}</h4>
              <p className="text">{text}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReferralInfo;
