import { bonuses } from "json/donate/donateInfoBonus";
import "./BoxInfo.sass";

const Boxinfo = () => {
  return (
    <div className="donate-box-form">
      {/* <DonateForm /> */}
      <div className="donate-box-bonus">
        <p className="donate-bonus-text">При поповненні:</p>
        <ul className="donate-bonus-list">
          {bonuses.map(({ id, money, bonus }) => (
            <p key={id} className="donate-bonus-text">
              від {money} гривень - {bonus}% бонусом
            </p>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Boxinfo;
