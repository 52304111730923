const UseLinkNavigate = (index: number) => {
  const navigateHandler = (index: number) => {
    function swtichToSection(section: string): void {
      const currentSection = document.getElementById(section);
      if (currentSection) currentSection.scrollIntoView({ behavior: "smooth" });
    }

    switch (index) {
      case 0:
        swtichToSection("howtoplay__section_wrapper");
        break;
      case 1:
        window.open("http://forum.liberty-rp.com.ua/", "_blank");
        break;
      case 2:
        window.open("https://discord.gg/2fFdnYqTyD", "_blank");
        break;
      case 3:
        swtichToSection("video__section");
        break;
      case 4:
        window.open(
          "https://store.steampowered.com/agecheck/app/271590/?l=russian",
          "_blank"
        );
        break;
      case 5:
        window.open("https://rage.mp/#if", "_blank");
        break;
    }
  };

  return navigateHandler(index);
};

export default UseLinkNavigate;
