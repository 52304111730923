import { useEffect, useState } from "react";

export default function useMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(true);

  useEffect(() => {
    const checkOrientation = () => {
      const screenHeight = window.innerHeight;
      if (
        !window.matchMedia("(orientation: portrait)").matches &&
        screenHeight < 450
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkOrientation();

    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  return isMobile;
}
