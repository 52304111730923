import { FC } from "react";
import "./documentation.sass";
import policyjson from "../../json/privacypoolicy.json";
import Footer from "components/Footer/Footer";
import Header from "components/Header/header";

interface IJsonProp {
  id: number;
  header?: string;
  info: string;
}

const Policy: FC<{ props: IJsonProp[]; title: string }> = ({
  props,
  title,
}) => {
  return (
    <>
      <section id="policy__block_wrapper">
        <div className="policy__content_wrapper">
          <div className="policy__content_title">
            <h1 className="policy__title">{title}</h1>
          </div>
          <div className="policy__content_block--wrapper">
            <div className="policy__content_block">
              {props.map((el: IJsonProp, id: number) => {
                return (
                  <div className="policy__block_wrapper" key={id}>
                    <h1 className="policy__block_title">
                      {el.id}. {el.header}
                    </h1>
                    <p
                      className="policy__block_description"
                      dangerouslySetInnerHTML={{ __html: el.info }}
                    ></p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
