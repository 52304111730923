import { images } from "json/imgGallery";
import { FC } from "react";
import "./SelectImgCirlce.sass";

interface IMyProps {
  changeImg: (id: number) => void;
  imgId: number;
}

const SelectImgCirlce: FC<IMyProps> = ({ changeImg, imgId }) => {
  return (
    <ul className="circle_list">
      {images.map(({ id }) => (
        <li
          onClick={() => changeImg(id)}
          className={`circle_item_img ${
            imgId === id && "circle__item_img_active"
          }`}
          key={id}
        ></li>
      ))}
    </ul>
  );
};

export default SelectImgCirlce;
