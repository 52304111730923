import { FC, useEffect } from "react";
import { createPortal } from "react-dom";
import "./modal.sass";

const modalRoot = document.querySelector("#modal-root");

interface IProps {
  close: () => void;
  children: any;
}

const Modal: FC<IProps> = ({ close, children }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    document.getElementsByTagName("body")[0].style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    };
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    const { code } = event;
    if (code === "Escape") {
      close();
    }
  };

  const handleClickBackground = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (event.currentTarget === event.target) {
      close();
    }
  };

  return createPortal(
    <div onClick={handleClickBackground} className="modal__background">
      {children}
    </div>,
    modalRoot as Element
  );
};

export default Modal;
