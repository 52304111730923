import "./Donate.sass";
import background from "assets/Images/backgroundliberty.png";
import jar from "assets/Images/Donate/jar.png";
import Boxinfo from "components/DonateCoins/BoxInfo/BoxInfo";
import DonateCoins from "components/DonateCoins/DonateCoins";
import DonateForm from "components/DonateForm/DonateForm";
import ListNumberInfo from "components/ListNumberInfo/ListNumberInfo";
import listinfo from "json/donate/donate.json";
import useWindowWidth from "hooks/useWidth";
import { bonuses } from "json/donate/donateInfoBonus";
import { FC, useState } from "react";
import Beta from "./beta/beta";

interface IPropsDonateCoins {
  mouseCords: { mouseX: number; mouseY: number };
}

const Donate: FC<IPropsDonateCoins> = ({ mouseCords }) => {
  const screenWidth = useWindowWidth();

  console.log(screenWidth);
  return (
    <div className="donate-wrapper">
      {/* <img className="donate-background-img" src={background} /> */}

      {/* <Beta /> */}
      <div className="donate-main-box">
        <DonateCoins mouseCords={mouseCords} />
        <div className="donate-box-title">
          <p className="donate-text-title">Донат</p>
        </div>
        <div className="donate-box-info">
          <img className="donate-box-info-img" src={jar} alt="jar" />
          <div className="donate-box-other">
            <DonateForm />
            {screenWidth >= 1920 && <Boxinfo />}
          </div>

          {screenWidth < 1920 && <Boxinfo />}
          <ListNumberInfo list={listinfo} />
        </div>
      </div>
    </div>
  );
};

export default Donate;
