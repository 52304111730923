import { FC, useEffect, useRef } from "react";
import "./howtoplay.sass";
import "./howtoplaymedia.sass";
import "assets/animation/animationGroup.sass";
import Howtoplaycard from "./howtoplaycard";
import howtoplaydata from "../../../json/howtoplay.json";
import image1 from "../../../assets/Images/HowToPlay/cardperson1.png";
import image2 from "../../../assets/Images/HowToPlay/cardperson2.png";
import image3 from "../../../assets/Images/HowToPlay/cardperson3.png";
import { useOnScreen, useMobileAnimation } from "hooks/index";

const _personsimages: string[] = [image1, image2, image3];

const Howtoplay: FC = () => {
  // const refMainElement = useRef<HTMLDivElement>(null);
  // const refListElement = useRef<HTMLUListElement>(null);
  // const isMobile = useMobileAnimation();
  // const isOnScreen = useOnScreen(refMainElement, "0px", isMobile ? 0.3 : 0.5);

  // useEffect(() => {
  //   const children = refListElement.current?.children;
  //   if (children) {
  //     if (isOnScreen) refListElement.current.style.opacity = "1";
  //     Array.from(children).forEach((child, ind) => {
  //       if (isOnScreen) {
  //         child.classList.add(`howtoplay__item_animation-in-${ind}`);
  //       } else {
  //       }
  //     });
  //   }
  //   return () => {
  //     if (children)
  //       Array.from(children).forEach((child, ind) => {
  //         child.classList.remove(`howtoplay__item_animation-in-${ind}`);
  //       });
  //   };
  // }, [isOnScreen]);

  return (
    <div id="howtoplay__section_wrapper">
      <h1 className="howtoplay__title">Як почати грати?</h1>
      <ul className="howtoplay__content">
        {howtoplaydata.map((el) => {
          return (
            <Howtoplaycard
              title={el.title}
              id={el.id}
              index={el.id}
              key={el.id}
              description={el.description}
              btn={el.btn}
              images={_personsimages}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Howtoplay;
