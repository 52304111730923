import "./MediaInfo.sass";
import sprites from "assets/Images/sprites/sprites.svg";
import laptop from "assets/Images/Referral/laptop.png";

export const MediaInfo = () => {
  const handleRelateLink = () => {
    window.open("https://forms.gle/qFrqydzcWZqjXbak6", "_blank");
  };
  return (
    <div className="media_box-main">
      <div className="media_box-title">
        <h3 className="media_title">Медійна персона ?</h3>
      </div>
      <div className="media_box-info">
        <img className="media_img" alt="" src={laptop} />
        <div className="media_item-info">
          <svg className="media_arrow-short">
            <use href={`${sprites}#shortArrowMedia`} />
          </svg>
          <svg className="media_arrow">
            <use href={`${sprites}#arrowMedia`} />
          </svg>
          <div className="media_box-text">
            <p className="media_text">
              Як гравець, запросіть{" "}
              <span className="media_friend">100 друзів або більше</span> до
              нашого серверу{" "}
            </p>
          </div>
        </div>
        <div className="media_item-info">
          <svg className="media_arrow-short">
            <use href={`${sprites}#shortArrowMedia`} />
          </svg>
          <svg className="media_arrow">
            <use href={`${sprites}#arrowMedia`} />
          </svg>
          <div className="media_box-text">
            <p className="media_text">
              Заповніть нашу гугл-форму по кнопці нижче
            </p>
            <button onClick={handleRelateLink} className="button-form">
              Заповнити форму
            </button>
          </div>
        </div>
        <div className="media_item-info">
          <svg className="media_arrow-short">
            <use href={`${sprites}#shortArrowMedia`} />
          </svg>
          <svg className="media_arrow">
            <use href={`${sprites}#arrowMedia`} />
          </svg>
          <div className="media_box-text">
            <p className="media_text">
              Очікуйте на повідомлення від нашої адміністрації та майбутню
              співпрацю
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaInfo;
