import React, { FC, useEffect, useState } from "react";
import "./modalLoader.sass";

interface IModalProps {
  text: string;
}

const ModalLodaer: FC<IModalProps> = ({ text }) => {
  return (
    <div className="modal__loader animated">
      <div className="loader-container ">
        <div className="loader">
          <svg
            width="80"
            height="65"
            viewBox="0 0 80 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lb-svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.3913 43.2981V28.9564H65.2769C73.4085 28.9564 80 22.4743 80 14.4782C80 6.4821 73.4085 0 65.2769 0H48.3199C51.7455 3.17211 53.8648 7.75198 53.8648 12.7444C53.8648 18.1564 51.3613 23.217 47.2141 26.4311L57.3913 43.2981ZM15.3609 0H37.0592C43.8834 0 49.4155 5.70588 49.4155 12.7444C49.4155 18.3435 45.9143 23.0991 41.0471 24.81L52.2024 43.2982H39.9098L30.3115 27.3903H26.2721V43.2982H15.3609V0ZM26.2267 18.9652C26.2267 19.4065 26.5867 19.7643 27.0307 19.7643H32.1344C35.3417 19.7643 37.9414 17.179 37.9414 13.9897V13.0125C37.9414 10.3074 35.7366 8.11483 33.0163 8.11483H27.2199C26.6714 8.11483 26.2267 8.55692 26.2267 9.10262V18.9652ZM10.9112 54.149H46.4569L42.5797 47.7232H54.8724L65.2517 65H0V0H10.9112V54.149ZM57.3913 18.9652V9.10262C57.3913 8.55692 57.836 8.11483 58.3844 8.11483H64.1809C66.9007 8.11483 69.1057 10.3074 69.1057 13.0125V13.9897C69.1057 17.179 66.5056 19.7643 63.2989 19.7643H58.1948C57.7509 19.7643 57.3913 19.4065 57.3913 18.9652Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <span className="modal__text">{text}</span>
    </div>
  );
};

export default ModalLodaer;
