import "./home.sass";
import { FC } from "react";
import GreetingSection from "./GreetingSection/greeting";
import VideoGalarySection from "./VideoGalarySection/VideoGalarySection";
import HowtoplaySection from "./HowToPlaySection/howtoplay";
import AnswerSection from "./AnswersSection/answer";
import Socials from "./Socials/Socials";
import ReferralSystem from './RefferalSystem/referralSystem'

const HomePage: FC = () => {
  return (
    <div className="home__main_box">
      <GreetingSection />
      <VideoGalarySection />
      <ReferralSystem />
      <HowtoplaySection />
      <Socials />
      <AnswerSection />
    </div>
  );
};

export default HomePage;
