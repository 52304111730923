import img1 from "../assets/Images/Home/img1.png";
import img2 from "../assets/Images/Home/img2.png";
import img3 from "../assets/Images/Home/img3.png";
import img4 from "../assets/Images/Home/img4.png";
import imgBig1 from "../assets/Images/Home/imgBig1.png";
import imgBig2 from "../assets/Images/Home/imgBig2.png";
import imgBig3 from "../assets/Images/Home/imgBig3.png";
import imgBig4 from "../assets/Images/Home/imgBig4.png";

export const images = [
  { id: 0, src: img1, srcBig: imgBig1 },
  { id: 1, src: img2, srcBig: imgBig2 },
  { id: 2, src: img3, srcBig: imgBig3 },
  { id: 3, src: img4, srcBig: imgBig4 },
];
