import oneStep from "assets/Images/Referral/onePng.png";
import twoStep from "assets/Images/Referral/twoPng.png";
import threeStep from "assets/Images/Referral/threePng.png";

export const steps = [
  {
    id: 1,
    number: "01",
    title: "Створіть аккаунт та зареєструйтесь",
    text: "Достатньо пройти реєстрацію на нашому сервері та створити персонажа.",
    img: {
      src: oneStep,
      width: "94px",
      height: "94px",
    },
  },
  {
    id: 2,
    number: "02",
    title: "Створіть власний реферальний код",
    text: "Перейдіть на голову сторінку, та введіть промокод “refferal”. Після чого вам стане доступний банер реферальної програми.",
    img: {
      src: twoStep,
      width: "148px",
      height: "149px",
    },
  },
  {
    id: 3,
    number: "03",
    title: "Поділіться кодом із Вашим ком’юніті",
    text: "Просувайтесь по шкалі подарунків, що разу як ваш реферал відіграє 10 годин або більше",
    img: {
      src: threeStep,
      width: "140px",
      height: "144px",
    },
  },
];
