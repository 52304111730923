import "./ReferralPrize.sass";
import prize from "assets/Images/Referral/prize.png";
import { infoPrize } from "./infoPrize";

const ReferralPrize = () => {
  return (
    <div className="box-prize">
      <div className="prize">
        <h3 className="title-prize">
          Загальні <span className="title-prize-animation">ПРИЗИ</span>
        </h3>
        <div className="div-info-prize">
          <p>*</p>
          <p className="text">список доступних призів за реферальну програму</p>
        </div>
        <img className="img-prize" src={prize} alt="prize" />
      </div>
      <div className="list-prize">
        {infoPrize.map(({ text, id }) => (
          <li key={id} className="one-item">
            {text}
          </li>
        ))}
      </div>
      <div className="div-info">
        <p>*</p>
        <p className="text">список доступних призів за реферальну програму</p>
      </div>
    </div>
  );
};

export default ReferralPrize;
