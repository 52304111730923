import gromoph from "assets/Images/Referral/gromoph.png";
import discord from "assets/Images/Referral/discord.png";
import star from "assets/Images/Referral/star.png";
import telega from "assets/Images/Referral/telega.png";
import twitter from "assets/Images/Referral/Twiter.png";
import youTube from "assets/Images/Referral/youTube.png";
import { FC } from "react";
import "./ImgOther.sass";

interface IPropAnimation {
  mouseCords: { mouseX: number; mouseY: number };
}
interface imgStyles {
  willChange: string;
  transform: string;
}

const ImgOther: FC<IPropAnimation> = ({ mouseCords }) => {
  const { mouseX, mouseY } = mouseCords;
  const imgStyles: imgStyles = {
    willChange: "transform",
    transform: `translate(${mouseX * 0.003}px, ${mouseY * 0.009}px)`,
  };

  const imgStylesTwo: imgStyles = {
    willChange: "transform",
    transform: `translate(${mouseX * 0.03}px, ${mouseY * 0.015}px)`,
  };

  return (
    <div className="box-img">
      <img className="gromophone" src={gromoph} />
      <div className="box-one" style={imgStyles}>
        <img className="star1" src={star} />
        <img className="star2" src={star} />
        <img className="star3" src={star} />

        <img className="telega" src={telega} />
        <img className="twitter" src={twitter} />
        <img className="youtube" src={youTube} />
      </div>
      <div className="box-two" style={imgStylesTwo}>
        <img className="discord" src={discord} />
        <img className="star4" src={star} />
        <img className="star5" src={star} />
        <img className="star6" src={star} />
        <img className="star7" src={star} />
      </div>
    </div>
  );
};

export default ImgOther;
