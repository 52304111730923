import { FC } from "react";
import StatisticCard from "./statisticCard";
import girlliberty from "../../../assets/Images/girlingreeting.png";
import girllibertymobile from "../../../assets/Images/Home/mobilegirl.png";
import "./greeting.sass";

export type CardStatus = "active" | "notactive";

//if you wand to change server status, just replace variables in isActiveServer
const _activeServer = "active";
// const _isntactiveServer = "notactive";
const isActiveServer: CardStatus = _activeServer;

const Greeting: FC = () => {
  return (
    <>
      <section id="homepage__wrapper">
        <div className="homepage__content">
          <div className="homepage__greeting">
            <div className="homepage__greeting_leftside">
              <h1 className="homepage__desc">Почни новe життя з проектом</h1>
              <h1 className="homepage__title">Liberty RolePlay!</h1>
              <StatisticCard isActive={isActiveServer} />
            </div>
          </div>
        </div>
        <img
          src={girlliberty}
          alt="homepage__girl"
          className="homepage__girl"
        />
        <img
          src={girllibertymobile}
          alt="homepage__girl_mobile"
          className="homepage__girl_mobile"
        />
      </section>
      <div className="homepage__mobileblock" />
    </>
  );
};

export default Greeting;
