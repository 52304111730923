import React, { FC, useContext } from "react";
import "./burger.css";
import { AppContext } from "Context";

interface BurgerProps {}

const Burger: FC<BurgerProps> = ({}) => {
  const { toogleMenuState, changeToogleMenuHandler }: any = useContext(
    AppContext as any
  );

  return (
    <label className="burger">
      <input
        type="checkbox"
        id="burger"
        checked={toogleMenuState}
        onChange={changeToogleMenuHandler}
      />
      <span></span>
      <span></span>
      <span></span>
    </label>
  );
};

export default Burger;
